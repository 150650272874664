//window.$ = require("jquery");

import Showdown from 'showdown'
import moment from 'moment'
import EasyMDE from 'easymde'
import { autocomplete } from '@algolia/autocomplete-js'

import * as bootstrap from './vendor/bootstrap.esm'
import './lib/localuser'
import './lib/log'
import './lib/dwm'
import './lib/editor'
import './lib/countdown'
import './lib/editor'
import './lib/http' 
import './lib/forms'
import './lib/profile_hover'
import './lib/attr_async'
import './lib/algolia_ac'
import './lib/user_ac'
import './lib/str'
import './lib/responsive'
import './lib/util'
import './lib/toast'
import './lib/viewtoggle'
import './lib/stripe_util'
import './lib/redirect'
import './lib/copy'
import './lib/workshop_item_ac'
import './lib/countdown_simple'

window.EasyMDE = EasyMDE;
window.moment = moment;
window.showdown = Showdown;
window.autocomplete = autocomplete;
window.bootstrap = bootstrap;