class LambdaResponsive
{
    init()
    {
        this.setupPageNavs();
    }

    setupPageNavs()
    {
        $(".nav-page").each(function() {

            $("#page_nav_content").html($(this).html());

            $(this).append(`<li class="page-nav-item-mobile"><a data-bs-toggle="offcanvas" href="#canvas_mobile_nav_page" role="button" aria-controls="canvas_mobile_nav_page"><i class="fa-regular fa-bars"></i> Menu</a></li>`);



        });

        AttrAsync.refresh();

    }

}

var ResponsiveHandler = new LambdaResponsive();

$(document).ready(function() {

    ResponsiveHandler.init();

});