class LambdaToast
{

    ToastIcon           = "fa-solid fa-comment";
    ToastTitle          = "New Notification";
    ToastCloseButton    = true;
    ToastTime           = new Date();
    ToastBody           = "Content here";
    ToastDeleteOnFade   = true;
    ToastHandle         = null;
    ToastDOM            = null;
    ToastBuilt          = false;
    ToastContainer      = "#lambda_notifs";
    ToastID             = null;

    static new()
    {
        return new LambdaToast();
    }

    withIcon(icon)
    {
        this.ToastIcon = icon;
        return this;
    }

    withTitle(title)
    {
        this.ToastTitle = title;
        return this;
    }

    withCloseButton(showButton)
    {
        this.ToastCloseButton = showButton;
        return this;
    }

    withTime(date)
    {
        this.ToastTime = date;
        return this;
    }

    withBody(body)
    {
        this.ToastBody = body;
        return this;
    }

    withDeleteOnFade(del)
    {
        this.ToastDeleteOnFade = del;
        return this;
    }


    build()
    {
        if(this.ToastBuilt)
            return this;

        this.ToastID = LambdaUtil.randomString(32);

        //Generate HTML
        $(this.ToastContainer).append(`
        <div class="toast" id="toast_${this.ToastID}" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header">
                <i class="${this.ToastIcon} me-2"></i>
                <strong class="me-auto">${this.ToastTitle}</strong>
                <small class="text-body-secondary">${moment(this.ToastTime).fromNow()}</small>
                ${this.ToastCloseButton ? `<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>` : `` }
            </div>
            <div class="toast-body">
                ${this.ToastBody}
            </div>
        </div>
        `);

        this.ToastDOM       = document.getElementById(`toast_${this.ToastID}`);
        this.ToastHandle    = bootstrap.Toast.getOrCreateInstance(this.ToastDOM);
        this.ToastBuilt     = true;
        var self            = this;

        this.ToastDOM.addEventListener("shown.bs.toast", () => {
            self.eventToastShow();
        });

        this.ToastDOM.addEventListener("hidden.bs.toast", () => {
            self.eventToastHide();
        });

        return this;

    }

    show()
    {
        if(!this.ToastBuilt)
        {
            this.build();
        }

        if(this.ToastHandle == null)
        {
            console.error("Toast handle is invalid");
            return this;
        }

        this.ToastHandle.show();

        return this;

    }

    hide()
    {
        if(!this.ToastBuilt)
        {
            console.error("Toast instance does not exist, cannot hide.");
            return this;
        }

        if(this.ToastHandle == null)
        {
            console.log("Toast handle is invalid");
            return this;
        }

        return this;
    }

    eventToastShow()
    {

    }

    eventToastHide()
    {
        if(this.ToastDeleteOnFade)
        {
            this.ToastHandle.dispose();
            this.ToastDOM.remove();

            this.ToastHandle    = null;
            this.ToastDOM       = null;
        }
    }

}

window.LambdaToast = LambdaToast;