class StripeUtil
{
    static showMessage(messageText)
    {
        const messageContainer = document.querySelector("#payment-message");

        messageContainer.classList.remove("hidden");
        messageContainer.textContent = messageText;

        setTimeout(function () {
            messageContainer.classList.add("hidden");
            messageText.textContent = "";
        }, 4000);
    }

    static showLoading(isLoading)
    {
        if (isLoading) 
        {
            // Disable the button and show a spinner
            document.querySelector("#submit").disabled = true;
            document.querySelector("#spinner").classList.remove("hidden");
            document.querySelector("#button-text").classList.add("hidden");
        } 
        else 
        {
            document.querySelector("#submit").disabled = false;
            document.querySelector("#spinner").classList.add("hidden");
            document.querySelector("#button-text").classList.remove("hidden");
        }
    }

    static async checkStatus()
    {
        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
        
        if (!clientSecret)
        {
            return;
        }
        
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
        
        switch (paymentIntent.status) 
        {
            case "succeeded":
                StripeUtil.showMessage("Payment succeeded!");
                break;
            case "processing":
                StripeUtil.showMessage("Your payment is processing.");
                break;
            case "requires_payment_method":
                StripeUtil.showMessage("Your payment was not successful, please try again.");
                break;
            default:
                StripeUtil.showMessage("Something went wrong.");
                break;
        }
    }

    static listenForSubmit(formSelector, onClick)
    {
        document.querySelector(formSelector).addEventListener('submit', event => {

            event.preventDefault();

            StripeUtil.showLoading(true);

            onClick(event);


        });
    }

}

window.StripeUtil = StripeUtil;