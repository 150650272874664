class Log
{

    static Info(text, data = null)
    {
        console.info("[INFO] " + text);

        if(data != null)
            console.log(data);
    }

    static Warn(text, data = null)
    {
        console.warn("[WARN] " + text);

        if(data != null)
            console.log(data);
    }

    static Error(text, data = null)
    {
        console.error("[ERROR] " + text);

        if(data != null)
            console.log(data);
    }

}

window.Log = Log;