class LambdaCountdown
{

    CountdownDate   = null;
    Elements        = {
        Years: null,
        Months: null,
        Weeks: null,
        Days: null,
        Hours: null,
        Minutes: null,
        Seconds: null
    };
    Interval        = null;
    PrependNumbers  = true;
    
    static ElementSegmentMap = {
        years: 'Years',
        months: 'Months',
        weeks: 'Weeks',
        days: 'Days',
        hours: 'Hours',
        minutes: 'Minutes',
        seconds: 'Seconds'
    };

    static fromElement(selector, bStart = true)
    {
        var elem = $(`#${selector}`);
        var date = elem.attr('data-countdown-date');

        if(date == null)
        {
            console.error(`${elem} does not have the data-countdown-date attribute`);
            return;
        }

        var elements = {};

        elem.children('[data-lt-segment]').each(function() {

            var segmentName = $(this).attr('data-lt-segment');
            var translatedName = LambdaCountdown.ElementSegmentMap[segmentName];

            //Get the child we're going to be using to display the data
            var displayElem = $(this).children('[data-lt-value="1"]').first();

            elements[translatedName] = displayElem;

        });

        if(elements.length == 0)
        {
            console.error("There are no HTML elements to be displayed..");
            return;
        }

        var obj = new LambdaCountdown();
        obj.setDate(new Date(date));
        obj.setElements(elements);

        if(bStart)
            obj.start();

        return obj;

    }

    setDate(date)
    {
        this.CountdownDate = date;
    }

    setElements(elems)
    {
        Object.keys(this.Elements).forEach(key => {

            if(elems[key] != null)
                this.Elements[key] = elems[key];

        });
    }

    setPrepend(bPrepend)
    {
        this.PrependNumbers = bPrepend;
    }

    start()
    {
        var self = this;

        this.Interval = setInterval(() => {

            var now         = new Date().getTime();
            var to          = self.CountdownDate.getTime();
            var timespan    = to - now;

            if(timespan < 0)
                timespan = 0;

            var weeks       = Math.floor(timespan / TimeDuration.Week);
            var days        = Math.floor(timespan / TimeDuration.Day);
            var weekDays    = (weeks > 0) ? Math.floor(days - (TimeDuration.WeekDays * weeks)) : days;
            var hours       = Math.floor((timespan % TimeDuration.Day) / TimeDuration.Hour);
            var minutes     = Math.floor((timespan % TimeDuration.Hour) / TimeDuration.Minute);
            var seconds     = Math.floor((timespan % TimeDuration.Minute) / TimeDuration.Second);
            var months      = Math.floor(days / TimeDuration.MonthDays);
            var years       = Math.floor(months / TimeDuration.YearMonths);

            if(self.Elements.Years != null)
                self.Elements.Years.html(self.formatNumber(years));

            if(self.Elements.Months != null)
                self.Elements.Months.html(self.formatNumber(months));

            if(self.Elements.Weeks != null)
                self.Elements.Weeks.html(self.formatNumber(weeks));

            if(self.Elements.Days != null)
                self.Elements.Days.html(self.formatNumber(weekDays));

            if(self.Elements.Hours)
                self.Elements.Hours.html(self.formatNumber(hours));

            if(self.Elements.Minutes)
                self.Elements.Minutes.html(self.formatNumber(minutes));

            if(self.Elements.Seconds)
                self.Elements.Seconds.html(self.formatNumber(seconds));         

        }, 1000);
    }

    formatNumber(num)
    {
        if(this.PrependNumbers)
            return (num < 10) ? `0${num}` : `${num}`;

        return `${num}`;
    }

    stop()
    {
        if(this.Interval != null)
        {
            clearInterval(this.Interval);
        }
    }

}

window.LambdaCountdown = LambdaCountdown;

class TimeDuration
{
    static Second       = 1000;
    static Minute       = TimeDuration.Second * 60;
    static Hour         = TimeDuration.Minute * 60;
    static Day          = TimeDuration.Hour * 24;
    static Week         = TimeDuration.Day * 7;
    static Month        = TimeDuration.Day * 30;
    static Year         = TimeDuration.Month * 12;

    static WeekDays     = 7;
    static MonthWeeks   = 4;
    static MonthDays    = 31;
    static YearMonths   = 12;
}