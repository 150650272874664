class LambdaCountdownSimple
{
    static init()
    {
        let timers = document.querySelectorAll('[data-simple-countdown="1"]');

        timers.forEach((elem) => {
            
            new LambdaCountdownSimple(elem);

        });
    }

    constructor(elem)
    {
        this.Element = elem;
        this.Timestamp = this.Element.getAttribute('data-countdown-timestamp');
        this.CompletedText = this.Element.getAttribute('data-countdown-completed-text');
        this.Format = this.Element.getAttribute('data-countdown-format');

        if(this.CompletedText == null || this.CompletedText == "") {
            this.CompletedText = "Completed";
        }

        if(this.Timestamp == null || this.Timestamp == "") {
            this.Format = "{d}:{h}:{m}:{s}";
        }

        this.onCounter();

        this.setupInterval();

    }

    setupInterval()
    {
        var self = this;

        this.Interval = setInterval(() => self.onCounter(), 1000);
    }

    deleteInterval()
    {
        clearInterval(this.Interval);
    }

    onCounter()
    {
        let now = Math.floor(Date.now() / 1000);
        let remaining = this.Timestamp - now;

        if(remaining <= 0) {
            this.Element.innerHTML = this.CompletedText;
            this.deleteInterval();
            return;
        }

        let days = Math.floor(remaining / 86400);
        let hours = Math.floor((remaining % 86400) / 3600);
        let minutes = Math.floor((remaining % 3600) / 60);
        let seconds = remaining % 60;

        let format = this.Format;
        format = format.replace("{d}", days.toString().padStart(2, '0'));
        format = format.replace("{h}", hours.toString().padStart(2, '0'));
        format = format.replace("{m}", minutes.toString().padStart(2, '0'));
        format = format.replace("{s}", seconds.toString().padStart(2, '0'));

        this.Element.innerHTML = format;

    }

}

window.LambdaCountdownSimple = LambdaCountdownSimple;

document.addEventListener("DOMContentLoaded", function(event) {

    LambdaCountdownSimple.init();

});