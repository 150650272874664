class LambdaStr
{
    static isNullOrEmpty(str)
    {
        return (!str || str.length == 0);
    }

    static isValidEmail(email)
    {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }
}

window.LambdaStr = LambdaStr;