import { createPopper } from '@popperjs/core';

class LambdaProfileHover
{

    ElemInstances = [];
    static TooltipHovered = false;

    init()
    {
        Log.Info("Starting profile hover...");
        
        var self = this;

        var elems = $(`[data-lambda-profilehover="1"]`);

        elems.each(function() {
            
            self.fromElement($(this));

        });

        $("#profile_hover_tooltip").hover(function() {

            LambdaProfileHover.TooltipHovered = true;

        }, function() {

            LambdaProfileHover.TooltipHovered = false;

            LambdaProfileHover.hideProfileHover();

        });

    }

    fromElement(elem)
    {
        var userid = elem.attr("data-lambda-userid");

        if(userid == null)
        {
            Log.Error("Element has no User ID attribute", elem);
            return;
        }

        this.ElemInstances.push(new ProfileHoverElement(elem, userid));

    }

    static showProfileHover()
    {
        $("#profile_hover_tooltip").removeClass("d-none");
        $("#profile_hover_tooltip").fadeIn("fast");
    }

    static showProfileLoading()
    {
        $("#profile_hover_tooltip").children('.profile_content').html(`
        <div class="d-flex flex-column justify-content-center align-items-center w-100 h-100">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        `);
    }

    static hideProfileHover()
    {
        $("#profile_hover_tooltip").fadeOut("fast");
    }

}

class ProfileHoverElement
{
    UserID          = 0;
    Element         = null;
    IsHovered       = false;
    HoverMinTime    = 1000; //How many seconds the element should be hovered before the tooltip shows.
    Timeout         = null;
    TimeoutEnd      = null;
    CurrentTooltips = [];

    constructor(elem, userid)
    {
        this.UserID = userid;
        this.init(elem);
    }

    init(elem)
    {

        var self        = this;
        this.Element    = elem;

        this.Element.hover(function() {

            self.IsHovered = true;

            self.onHover();

        },
        function() {

            self.IsHovered = false;

            self.onHoverFinished();

        });
    }

    onHover()
    {

        var self = this;

        if(this.Timeout != null)
            clearTimeout(this.Timeout);

        this.Timeout = setTimeout(() => {

            if(this.IsHovered)
                self.showProfileToolip(); //We only want to show the profile if the user is hovered for a second or more, because the mouse might just be passing the element.

        }, this.HoverMinTime);
    }

    onHoverFinished()
    {
        //LambdaProfileHover.hideProfileHover();

        if(this.TimeoutEnd != null)
            clearTimeout(this.TimeoutEnd);

        this.TimeoutEnd = setTimeout(() => {
            
            if(!LambdaProfileHover.TooltipHovered)
                LambdaProfileHover.hideProfileHover();

        }, this.HoverMinTime);
    }

    showProfileToolip()
    {
        var tooltip     = document.getElementById("profile_hover_tooltip");

        createPopper(this.Element[0], tooltip, {
            placement: 'auto'
        });

        LambdaProfileHover.showProfileHover();
        LambdaProfileHover.showProfileLoading();

        $.get(route('async.view.user.profile', [this.UserID]), function(data) {

            $("#profile_hover_tooltip").children('.profile_content').html(data);

            //Enable tooltips
            //var elem = document.getElementById("profile_hover_tooltip");
            //var tooltips = elem.querySelectorAll('[data-bs-toggle="tooltip"]');

            //var tooltipList = [...tooltips].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

        });

    }

}

var ProfileHover = new LambdaProfileHover();

window.LambdaProfileHover = LambdaProfileHover;
window.ProfileHoverElement = ProfileHoverElement;
window.ProfileHover = ProfileHover;

$(document).ready(() => ProfileHover.init());