class LambdaAttrAsync
{

    WindowSizeTranslate = {
        sm:         LambdaModalSize.Small,
        default:    LambdaModalSize.Default,
        lg:         LambdaModalSize.Large,
        xl:         LambdaModalSize.ExtraLarge
    };

    init()
    {
        
        var self = this;

        $(`[data-async-enabled="1"]`).each(function() {

            self.setupAsync($(this));

        });

        $(`[data-vasync-enabled="1"]`).each(function() {

            self.setupViewAsync($(this));

        });
    }

    refresh()
    {
        //console.log("Javascript changed the DOM layout, refreshing Attr Async.");

        $(`[data-async-enabled="1"]`).unbind();
        $(`[data-vasync-enabled="1"]`).unbind();

        this.init();

    }

    setupAsync(elem)
    {

        var self = this;

        elem.click(function(event) {

            event.preventDefault();

            var url         = elem.attr("href");
            var title       = elem.attr("data-async-title");
            var icon        = elem.attr("data-async-title-icon");
            var body        = elem.attr("data-async-body");
            var size        = elem.attr("data-async-size");
            var cChildren   = elem.attr("data-has-clickable-children");
            var useMarkdown = elem.attr("data-async-body-md");
            var doRedirect  = (parseInt(elem.attr("data-async-redirect")) === 1) ? true : false;

            console.log(parseInt(elem.attr("data-async-redirect")));
            console.log(elem.attr("data-async-redirect"));

            if(cChildren != undefined)
                cChildren = (parseInt(cChildren) == 1) ? true : false;
            else
                cChildren = false;

            if(useMarkdown != undefined)
                useMarkdown = (parseInt(useMarkdown) == 1) ? true : false;
            else
                useMarkdown = false;

            if(cChildren)
            {
                //We're only going to look for buttons
                var target = event.target;
                
                if(target.nodeName == "BUTTON" || target.parentNode.nodeName == "BUTTON")
                    return;

            }

            var mSize   = LambdaModalSize.Default;

            if(size != null)
            {

                var reqSize = self.WindowSizeTranslate[size];

                if(reqSize != null)
                    mSize = reqSize;

            }

            if(useMarkdown == true)
            {
                var converter = new showdown.Converter();
                body = converter.makeHtml(body);
            }

            console.log(doRedirect);

            var modal   = new LambdaModal();
            modal.setTitle(`<i class="${icon}"></i> ${title}`);
            modal.setSize(mSize);
            modal.setStaticBackdrop(true);
            modal.setCloseButton(false);
            modal.setBody(body);
            modal.addCloseButton();
            modal.addSuccessButton('Continue', () => {

                modal.asyncPOST(url, {}, true, true, false);

            }, 'fa-solid fa-check');
            modal.render();
            modal.show();
            modal.eventAsyncSuccess((data) => {

                if(doRedirect === true && data.Redirect === true)
                {

                    setTimeout(() => {

                        location.href = data.RedirectTo;

                    }, 2000);

                    modal.showSuccess(data.Message);

                    return true;
                }

            });
            modal.eventAsyncCompleted(() => {

                if(doRedirect === true)
                {
                    return true;
                }

            });

        });
    }

    setupViewAsync(elem)
    {

        var self = this;

        elem.click(function(event) {

            event.preventDefault();

            var url         = elem.attr("data-vasync-route");
            var title       = elem.attr("data-vasync-title");
            var icon        = elem.attr("data-vasync-title-icon");
            var size        = elem.attr("data-vasync-size");
            var callfunc    = elem.attr("data-vasync-func-complete");

            var mSize   = LambdaModalSize.Default;

            if(size != null)
            {
                var reqSize = self.WindowSizeTranslate[size];

                if(reqSize != null)
                    mSize = reqSize;

            }

            var modal   = new LambdaModal();
            modal.setSize(mSize);
            modal.setStaticBackdrop(true);
            modal.setCloseButton(false);
            modal.setLoading();
            modal.render();
            modal.show();

            modal.setBodyURL(url, () => {

                modal.setTitle(`<i class="${icon}"></i> ${title}`);
                modal.addCloseButton();

                if(callfunc != null)
                {
                    var func = window[callfunc];
                    func(modal);
                }

            });

        });
    }
}

let AttrAsync = new LambdaAttrAsync();

window.LambdaAttrAsync = LambdaAttrAsync;
window.AttrAsync = AttrAsync;

$(document).ready(function() {

    AttrAsync.init();

});