class LambdaUtil
{
    static userBasicInfo(id, onSuccess, onFail)
    {

        if(onSuccess == null)
        {
            console.error("No success function specified for LambdaUtil.userBasicInfo");
            return;
        }

        LambdaHTTP.Get(route('async.user.info.basic', [id])).OnSuccess(data => {

            onSuccess(data.Data);

        }).OnFail(data => {

            if(onFail != null)
                onFail(data);

        }).Send();
    }

    static randomString(len)
    {
        let result              = '';
        const characters        = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength  = characters.length;
        let counter             = 0;
        while (counter < len) 
        {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        
        return result;
    }

}

window.LambdaUtil = LambdaUtil;