class LocalUser
{
    static IsSetup      = false;
    static IsGuest      = true;
    static IsSignedIn   = false;
    static ID           = 0;
    static DisplayName  = "Guest";
    static Email        = null;
    static Locale       = "en-GB";
    static Currency     = "GBP";
    static Callbacks    = [];

    static setup(user)
    {

        LocalUser.IsSetup       = true;
        LocalUser.IsGuest       = false;
        LocalUser.IsSignedIn    = true;
        LocalUser.ID            = user.id;
        LocalUser.DisplayName   = user.name;
        LocalUser.Email         = user.email;
        LocalUser.Locale        = user.locale;
        LocalUser.Currency      = user.currency.code;

        LocalUser.Callbacks.forEach(fn => {
            fn();   
        })

        LocalUser.Callbacks = [];

    }

    static onSetup(func)
    {

        if(LocalUser.IsSetup)
        {
            func();
            return;
        }

        LocalUser.Callbacks.push(func);
    }

}

window.LocalUser = LocalUser;