class WorkshopItemAC extends AlgoliaAC
{

    ContainerElement        = null;
    SearchParameter         = "name";
    DisplayParameter        = "title";
    ShowSelfMadeItemsOnly   = false;

    constructor(selector, id, onlySelf = false)
    {

        super(`#${id}`);

        var elem = $(selector);

        elem.html(`
            <div class="d-flex flex-column gap-2">
                <div class="d-flex flex-row gap-3 flex-wrap wsitem-ac-selected-items">

                </div>
                <div id="${id}"></div>
            </div>
        `);

        this.ContainerElement       = elem;
        this.Container              = `#${id}`;
        this.ShowSelfMadeItemsOnly  = onlySelf;

        this.setEndpoint(route('async.workshop.search.item', { self: (onlySelf === true) ? 1 : 0 }), true);

        this.itemTemplate((item, html) => this.renderTemplate(item, html));
        this.onSelected(item => this.itemSelected(item));

        this.render();

    }

    renderTemplate(item, html)
    {
        return html`
        <div class="d-flex flex-row gap-2 align-items-center">
            <img src="${item.avatar}" class="avatar-sm border rounded-circle border-primary border-2" alt="${item.title}" />
            <div>${item.title}</div>
        </div>
        `;
    }

    renderTemplateAdded(item)
    {
        return `
        <div class="d-flex flex-row gap-2 align-items-center">
            <img src="${item.avatar}" class="avatar-sm border rounded-circle border-primary border-2 flex-grow-0 flex-shrink-0" alt="${item.title}" />
            <div class="flex-grow-1 flex-shrink-1">${item.title}</div>
            <div class="flex-grow-0 flex-shrink-0">
                <a href="#" class="btn btn-danger btn-sm btn-ac-multiselect-remove" data-ac-item-id="${item.id}"><i class="fa-solid fa-trash"></i></a>
            </div>
        </div>
        `;
    }

    refreshUI(items)
    {
        var self = this;

        //Get the selected container
        var container = this.ContainerElement.children().children('.wsitem-ac-selected-items');
        container.empty();

        items.forEach(item => {

            container.append(self.renderTemplateAdded(item));

        });

        $(".btn-ac-multiselect-remove").off('click');

        $(".btn-ac-multiselect-remove").click(function(event) {

            event.preventDefault();

            var id = $(this).attr('data-ac-item-id');

            var item = self.getItemByIdentity(id);

            self.removeSelectedItem(item);

            self.refreshUI(self.SelectedItems);

        });

    }

    itemSelected(item)
    {
        this.refreshUI(this.SelectedItems);
    }

}

window.WorkshopItemAC = WorkshopItemAC;