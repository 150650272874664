class LambdaEditor
{

    static createFromDoms()
    {
        var doms = $("[data-ck-enable='1']");

        doms.each(function() {

            LambdaEditor.createFromElement($(this)[0]);

        });

        $("[data-mde-enable='1']").each(function() {

            LambdaEditor.createMDFromElement($(this)[0]);

        });

    }

    static createFromElement(elem)
    {

        var promise = new Promise(function(resolve, reject) {

            var url = route('async.editor.media.upload');
            var csrf = LambdaHTTP.CSRFToken();

            var hndl = CKSource.Editor.create(elem, {

                simpleUpload: {
                    uploadUrl: url,
                    headers: {
                        'X-CSRF-TOKEN': csrf
                    }
                }
                
            }).then(editor => {

                resolve(editor);

            }).catch(error => {

                reject(error);

            });

        });

        return promise;

    }

    static create(qrySelector)
    {
        return LambdaEditor.createFromElement(document.querySelector(qrySelector));
    }

    static createMarkdown(qrySelector)
    {
        return LambdaEditor.createMDFromElement(document.querySelector(qrySelector));
    }

    static createMDFromElement(elem)
    {
        var editor = new EasyMDE({
            element: elem,
            toolbar: [
                "bold",
                "italic",
                "heading",
                "|",
                "quote",
                "unordered-list",
                "ordered-list",
                "|",
                "link",
                "image",
                "|",
                "guide"
            ],
            maxHeight: 150,
        });

        return editor;
    }

}

window.LambdaEditor = LambdaEditor;

$(document).ready(function() {

    LambdaEditor.createFromDoms();

});