class LambdaRedirect
{

    static SiteName         = 'Website';
    static IsRedirectPage   = false;
    static SafeURL          = {};

    static setSiteName(name)
    {
        LambdaRedirect.SiteName = name;
    }

    static setRedirectPage(bRedirPage)
    {
        LambdaRedirect.IsRedirectPage = bRedirPage;
    }

    static setSafeLinkList(links)
    {
        links.forEach(item => {

            LambdaRedirect.SafeURL[item.domain] = true;

        });
    }

    static listenForClicks()
    {

        document.addEventListener("click", e => {

            const origin = e.target.closest('a');

            if(origin)
            {
                const href = origin.href;
                var ignore = origin.getAttribute("data-redirect-ignore");

                if(ignore != null && parseInt(ignore) === 1)
                {
                    return;
                }
                
                if(href.startsWith("http"))
                {
                    
                    var url = new URL(href);
                    var domain = url.hostname;

                    if(!(LambdaRedirect.SafeURL[domain]) && e.ctrlKey === false)
                    {
                        e.preventDefault();

                        LambdaRedirect.showUI(url, true);

                    }

                }

            }

        });
    }

    static showUI(url, external)
    {

        var modal = new LambdaModal();
        modal.setSize(LambdaModalSize.ExtraLarge);
        modal.setStaticBackdrop(true);
        modal.setCloseButton(false);
        modal.setTitle((external) ? `<i class="fa-solid fa-triangle-exclamation"></i> Leaving ${LambdaRedirect.SiteName}` : `<i class="fa-sharp fa-solid fa-arrow-right"></i> Redirecting`);
        
        if(external)
        {
            modal.setBody(`
                <div class="alert alert-warning">
                    <p><i class="fa-solid fa-triangle-exclamation"></i> <strong>Warning:</strong> You are about to leave Lambda Networks and go to an external URL.</p>
                    <p><strong>URL:</strong> ${url}</p>
                    <p>Lambda is not responsible for content on third-party websites.</p>
                </div>
            `);
        }
        else
        {
            modal.setBody(`
            <div class="d-flex justify-content-center mb-3">
                <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="alert alert-info">
                <p><i class="fa-sharp fa-solid fa-arrow-right"></i> You are about to be redirected</p>
                <p><strong>URL:</strong> ${url}</p>
            </div>
            `);
        }

        if(external)
        {
            modal.addCloseButton();
            modal.addSuccessButton('Continue', () => {

                location.href = url;

            }, 'fa-solid fa-check');
        }
        else
        {

            var timeout = setTimeout(() => {

                location.href = url;

            }, 5000);

            modal.addButton(new LambdaButton().setClass("btn-danger").setButtonType(LambdaButtonType.FormButton).setIcon('fa-solid fa-xmark').setText('Cancel').eventClick(() => {

                clearTimeout(timeout);
                modal.hide();

                if(LambdaRedirect.IsRedirectPage)
                {
                    location.href = route('front.home');
                }

            }));
        }

        modal.render();
        modal.show();

    }

}

window.LambdaRedirect = LambdaRedirect;

$(document).ready(function() {

    LambdaRedirect.listenForClicks();

});