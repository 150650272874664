class AlgoliaAC
{

    Container               = null;
    EndpointURL             = null;
    IsRoute                 = false;
    SearchParameter         = "query";
    DisplayParameter        = "value";
    IdentityParameter       = "id";
    Multiselect             = false;
    SelectedItems           = [];
    ItemTemplateCallback    = function(item, html) {
        return item.value;
    };

    SelectedCallback        = function(item) {

    };
    URLCallback             = function(item) {
        return 'javascript:void(0);'
    };

    constructor(selector)
    {
        this.Container = selector;
    }

    setEndpoint(url, isRoute = true)
    {
        this.EndpointURL    = url;
        this.IsRoute        = isRoute;

        return this;

    }

    setQueryParam(param)
    {
        this.SearchParameter = param;

        return this;
    }

    setDisplayParam(param)
    {
        this.DisplayParameter = param;

        return this;
    }

    setIdentityParam(param)
    {
        this.IdentityParameter = param;

        return this;
    }

    itemTemplate(func)
    {
        this.ItemTemplateCallback = func;

        return this;
    }

    onSelected(func)
    {
        this.SelectedCallback = func;

        return this;
    }

    setMultiselect(bSelect)
    {
        this.Multiselect = bSelect;

        return this;
    }

    getSelectedItems()
    {
        return this.SelectedItems;
    }

    itemAlreadySelected(item)
    {
        var items = this.SelectedItems.filter(fItem => item[this.IdentityParameter] == fItem[this.IdentityParameter]);

        if(items.length > 0)
            return true;

        return false;

    }

    getItemByIdentity(id)
    {
        var items = this.SelectedItems.filter(fItem => id == fItem[this.IdentityParameter]);

        if(items.length > 0)
            return items[0];

        return null;

    }

    addSelectedItem(item)
    {
        if(!this.Multiselect)
            this.SelectedItems = []; //Clear the list

        if(this.itemAlreadySelected(item))
            return this;

        this.SelectedItems.push(item);

        return this;
    }

    removeSelectedItem(item)
    {
        var items = this.SelectedItems.filter(fItem => item !== fItem);
        
        this.SelectedItems = items;

        return this;

    }

    render()
    {
        var self = this;

        autocomplete({
            container: this.Container,
            getSources({ setActiveItemId, setIsOpen, refresh, setQuery }) 
            {
                return [
                    {
                        sourceId: 'main',
                        async getItems({ query }) 
                        {
        
                            var result = await new Promise((resolve, reject) => {
        
                                LambdaHTTP.Post(self.EndpointURL, self.IsRoute).AddFormValue(self.SearchParameter, query).OnSuccess(data => {
        
                                    resolve(data.Data.results);
        
                                }).OnFail(data => {
        
                                    reject(data.Message);
        
                                }).Send();
        
                            });
        
                            //return result.filter(item =>
                            //    item[self.DisplayParameter].toLowerCase().includes(query.toLowerCase())
                            //);

                            return result; //Since we're searching on the API endpoint, we shouldn't need to do JS filtering.
        
                        },
                        getItemUrl({ item }) 
                        {
                            return self.URLCallback(item);
                        },
                        getItemInputValue({ item }) 
                        {
                            return item[self.DisplayParameter];
                        },
                        onSelect({state, event, item, itemurl, source})
                        {

                            self.addSelectedItem(item);

                            var override = self.SelectedCallback(item, state, event, source);

                            if(override == true)
                                return;
        
                            setActiveItemId(state.activeItemId);
                            setIsOpen(false);
                            setQuery('');
                            refresh();
        
                        },
                        templates: 
                        {
                            item({ item, html }) 
                            {
                                return self.ItemTemplateCallback(item, html);
                            },
                        },
                    },
                ];
            },
        });
    }

}

window.AlgoliaAC = AlgoliaAC;