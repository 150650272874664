class LambdaForms
{

    Selector                = null;
    Form                    = null;
    FormNative              = null;
    EventOnSubmit           = null;
    SubmitData              = [];
    HasFailedValidation     = true;

    static from(id)
    {
        var obj = new LambdaForms();
        obj.init(id);

        return obj;
    }

    init(id)
    {
        this.Selector       = id;
        this.Form           = $(`#${this.Selector}`);
        this.FormNative     = document.getElementById(this.Selector);
        this.EventOnSubmit  = function() {}

        this.setupForm();
        this.setupConditionalFields();

    }

    setupForm()
    {

        var self = this;

        this.Form.addClass("need-validation");
        this.Form.attr("novalidate", "");
        this.Form.submit(event =>  {

            self.onSubmitInternal(event);

        });
    }

    setupConditionalFields()
    {
        var conditional = this.Form.find('[data-lform-conditional=1]');

        var self = this;

        conditional.each(function() {

            var fieldName       = $(this).attr("data-lform-show-name");
            var fieldValue      = $(this).attr("data-lform-show-value");
            var fieldRequired   = (parseInt($(this).attr("data-lform-required")) == 1) ? true : false;
            var field           = $(this);
            var fieldInput      = $(this).find('[data-lform-input=1]');

            if(fieldName == null)
            {
                console.error($(this), "is marked as conditional but has no field NAME to listen to");
                return;
            }

            if(fieldValue == null)
            {
                console.error($(this), "is marked as conditional but has no field VALUE to listen to");
                return;
            }

            var listenObj = self.Form.find(`[name=${fieldName}]`);

            if(listenObj.length == 0)
            {
                console.error($(this), `cannot find object to listen to, name = ${fieldName}`);
                return;
            }

            listenObj.change(function() {

                var data = $(this).val();

                if(data == fieldValue)
                {
                    field.removeClass("d-none");

                    if(fieldRequired)
                        fieldInput.prop("required", true);

                }
                else
                {
                    field.addClass("d-none");

                    if(fieldRequired)
                        fieldInput.prop("required", false);

                }

            });

            if(listenObj.val() == fieldValue)
                listenObj.change();

        });

    }

    onSubmitInternal(event)
    {
        event.preventDefault();
        event.stopPropagation();

        if(!this.FormNative.checkValidity())
        {
            this.FormNative.classList.add('was-validated');
            return;
        }

        this.HasFailedValidation = false;

        this.FormNative.classList.add('was-validated');

        const data      = new FormData(event.target);
        const value     = Object.fromEntries(data.entries());

        this.SubmitData = value;

        this.EventOnSubmit(this.SubmitData, event);
    }

    onSubmit(func)
    {
        this.EventOnSubmit = func;
    }

    submit()
    {
        this.Form.submit();
    }

    getData()
    {
        return this.SubmitData;
    }

    setDisabled(bDisable = true)
    {
        $(`#${this.Selector} :input`).prop('disabled', bDisable);
    }

    showLoading(bLoad = true)
    {
        if(bLoad)
        {

            if(this.Form.children("#loader").length > 0)
            {
                return;
            }

            this.Form.prepend(`
            <div class="d-flex justify-content-center" id="loader">
                <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            `);
        }
        else
        {
            this.Form.children("#loader").remove();
        }
    }

    showAlert(name, html)
    {
        if(this.Form.children(".alert").length > 0)
        {
            this.Form.children('.alert').remove();
        }

        this.Form.prepend(`
        <div class="alert alert-${name}">${html}</div>
        `);

    }

    alertInfo(html)
    {
        this.showAlert('info', html);
    }

    alertSuccess(html)
    {
        this.showAlert('success', html);
    }

    alertDanger(html)
    {
        this.showAlert('danger', html);
    }

    alertWarning(html)
    {
        this.showAlert('warning', html);
    }

    post(routeURL, isRoute = true, onSuccess = function() {}, onFail = function() {})
    {
        this.setDisabled(true);
        this.showLoading(true);

        LambdaHTTP.Post(routeURL, isRoute).AddFormValues(this.getData()).OnSuccess(data => {

            var override = onSuccess(data);

            if(override != null && override == true)
                return;

            this.alertSuccess(data.Message);

        }).OnFail(data => {

            var override = onFail(data);

            if(override != null && override == true)
                return;

            this.alertDanger(LambdaHTTP.GetHTMLErrors(data));

        }).OnCompleted(() => {

            this.setDisabled(false);
            this.showLoading(false);

        }).Send();
    }

    static setupCheckboxBooleans()
    {
        $(".check-bool").each(function() {

            var name    = $(this).attr("name");
            var checked = $(this).prop("checked");
    
            $(this).val((checked) ? "1" : "0");
    
            $(this).parent().prepend(`<input type="hidden" name="${name}" value="0">`);
    
        });

        $(".check-bool").change(function() {

            var checked = $(this).prop("checked");
    
            $(this).val((checked) ? "1" : "0");
    
        });

    }

}

class LambdaFormsAttr
{

    init()
    {
        $(`[data-async-form="1"]`).each(function() {

            var id      = $(this).attr("id");
            var action  = $(this).attr("action");

            if(id == null)
            {
                console.error("Form does not have an ID");
                console.error($(this)[0]);
                return;
            }

            var form = LambdaForms.from(id);

            form.onSubmit(data => {

                form.post(action, true, (data) => {

                    form.setDisabled(false);

                    location.reload();

                });

            });

        });
    }

}

var FormsAttr = new LambdaFormsAttr();

window.LambdaForms = LambdaForms;
window.LambdaFormsAttr = LambdaFormsAttr;
window.FormsAttr = FormsAttr;

$(document).ready(function() {

    LambdaForms.setupCheckboxBooleans();
    FormsAttr.init();

});