class UserAC extends AlgoliaAC
{

    ContainerElement        = null;
    SearchParameter         = "search";
    DisplayParameter        = "name";

    constructor(selector, id)
    {

        super(`#${id}`);

        var elem = $(selector);

        elem.html(`
            <div class="d-flex flex-column gap-2">
                <div class="d-flex flex-row gap-3 flex-wrap user-ac-selected-items">

                </div>
                <div id="${id}"></div>
            </div>
        `);

        this.ContainerElement   = elem;
        this.Container          = `#${id}`;

        this.setEndpoint(route('async.search.user'), true); //This can be overridden if needed.

        //this.setMultiselect(true); //Just for testing!

        this.itemTemplate((item, html) => this.renderTemplate(item, html));
        this.onSelected(item => this.userSelected(item));

        this.render();

    }

    renderTemplate(item, html)
    {
        return html`
        <div class="d-flex flex-row gap-2 align-items-center">
            <img src="${item.avatar}" class="avatar-sm border rounded-circle border-primary border-2" alt="${item.name}" />
            <div>${item.name}</div>
        </div>
        `;
    }

    renderTemplateAdded(item)
    {
        return `
        <div class="d-flex flex-row gap-2 align-items-center">
            <img src="${item.avatar}" class="avatar-sm border rounded-circle border-primary border-2 flex-grow-0 flex-shrink-0" alt="${item.name}" />
            <div class="flex-grow-1 flex-shrink-1">${item.name}</div>
            <div class="flex-grow-0 flex-shrink-0">
                <a href="#" class="btn btn-danger btn-sm btn-ac-multiselect-remove" data-ac-item-id="${item.id}"><i class="fa-solid fa-trash"></i></a>
            </div>
        </div>
        `;
    }

    refreshUI(items)
    {
        var self = this;

        //Get the selected container
        var container = this.ContainerElement.children().children('.user-ac-selected-items');
        container.empty();

        items.forEach(item => {

            container.append(self.renderTemplateAdded(item));

        });

        $(".btn-ac-multiselect-remove").off('click');

        $(".btn-ac-multiselect-remove").click(function(event) {

            event.preventDefault();

            var id = $(this).attr('data-ac-item-id');

            var item = self.getItemByIdentity(id);

            self.removeSelectedItem(item);

            self.refreshUI(self.SelectedItems);

        });

    }

    userSelected(user)
    {
        this.refreshUI(this.SelectedItems);
    }

}

window.UserAC = UserAC;