class LambdaViewToggle
{

    ViewClass           = null; //The class each "view" has so that all views can be hidden
    DefaultView         = null; //The default view to use if one isn't stored already
    ValidViews          = []; //List of valid view names
    StorageName         = null; //The name to use for local storage to save which view the user prefers
    HasToggleButton     = false; //Do we have a button to toggle the views?
    ToggleButtonClass   = null; //The classname of the button clicks to listen to
    ViewChangedCallback = function() {}; //Callback when the view has changed.

    constructor(viewClass, defView, storeName, viewList = [])
    {
        this.ViewClass      = viewClass;
        this.DefaultView    = defView;
        this.StorageName    = storeName;
        this.ValidViews     = viewList;
    }

    addValidView(viewName)
    {
        this.ValidViews.push(viewName);

        return this;
    }

    withToggleButton(className)
    {
        this.HasToggleButton    = true;
        this.ToggleButtonClass  = className;

        return this;
    }

    withChangeCallback(func)
    {
        this.ViewChangedCallback = func;

        return this;
    }

    init()
    {
        var self = this;

        if(this.DefaultView == null)
        {
            console.error("No default view is specified");
            return;
        }

        if(this.ValidViews.length == 0)
            console.warn("No valid views have been specified, view toggling may not work");

        if(this.HasToggleButton)
        {
            $(`.${this.ToggleButtonClass}`).click(function(e) {
                e.preventDefault();
                self.toggleButtonClicked($(this));
            });
        }

        if(this.StorageName != null)
        {
            var storedView = localStorage.getItem(this.StorageName);

            if(storedView != null && this.isValidView(storedView))
                this.showViewName(storedView);

            if(storedView != null && this.isValidView(storedView) == false)
            {
                localStorage.setItem(this.StorageName, this.DefaultView);
                this.showViewName(this.DefaultView);
            }

            if(storedView == null)
            {
                localStorage.setItem(this.StorageName, this.DefaultView);
                this.showViewName(this.DefaultView);
            }
        }

    }

    toggleButtonClicked(btn)
    {
        this.showViewBtn(btn);
    }

    isValidView(viewName)
    {
        return this.ValidViews.includes(viewName);
    }

    showViewName(viewName)
    {
        if(!this.isValidView(viewName))
        {
            console.error(`Invalid view name: ${viewName}`);
            return;
        }

        var btn = $(`[data-view-toggle=${viewName}]`);

        if(btn.length > 0)
            this.toggleButton(btn);

        this.toggleView(viewName);
    }

    showViewBtn(btn)
    {
        console.log("Showing view based on button");
        console.log(btn);

        var viewName = btn.attr("data-view-toggle");

        if(viewName == null)
        {
            console.error(`View Toggle Button ${btn} does not specify the data-view-toggle attribute`);
            return;
        }

        if(!this.isValidView(viewName))
        {
            console.error(`Invalid View: ${viewName}. Source Button: ${btn}`);
            return;
        }

        this.toggleButton(btn);
        this.toggleView(viewName);

        localStorage.setItem(this.StorageName, viewName);

    }

    toggleView(viewName)
    {
        $(`.${this.ViewClass}`).addClass("d-none"); //Hide everything
        $(`.${viewName}`).removeClass("d-none"); //Show the specified view
    }

    toggleButton(btn)
    {
        $(`.${this.ToggleButtonClass}`).removeClass("active");
        btn.addClass("active");
    }

}

window.LambdaViewToggle = LambdaViewToggle;